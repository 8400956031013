<template>
  <b-card
    class="customerservice-edit-wrapper"
  >
    <!-- form -->
    <b-form id="customerserviceForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="平台ID"
            label-for="store_company_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_company_id"
              size="sm"
              v-model="customerservice.store_company_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售场次"
            label-for="sales_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_name"
              size="sm"
              v-model="customerservice.sales_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺Id"
            label-for="store_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_id"
              size="sm"
              v-model="customerservice.store_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺名称"
            label-for="store_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="store_name"
              size="sm"
              v-model="customerservice.store_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部订单编号"
            label-for="out_order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_order_no"
              size="sm"
              v-model="customerservice.out_order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售后单号"
            label-for="customer_service_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="customer_service_no"
              size="sm"
              v-model="customerservice.customer_service_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售日期"
            label-for="sales_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_time"
              size="sm"
              v-model="customerservice.sales_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品名称"
            label-for="product_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_name"
              size="sm"
              v-model="customerservice.product_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="customerservice.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品名称"
            label-for="out_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_name"
              size="sm"
              v-model="customerservice.out_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部商品ID"
            label-for="out_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="out_id"
              size="sm"
              v-model="customerservice.out_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售后原因"
            label-for="reason"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="reason"
              size="sm"
              v-model="customerservice.reason"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售后原因ID"
            label-for="reason_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="reason_id"
              size="sm"
              v-model="customerservice.reason_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退款总份数"
            label-for="after_sales_total"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="after_sales_total"
              size="sm"
              v-model="customerservice.after_sales_total"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退款总金额"
            label-for="after_sales_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="after_sales_amount"
              size="sm"
              v-model="customerservice.after_sales_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="返回金额"
            label-for="appeal_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="appeal_amount"
              size="sm"
              v-model="customerservice.appeal_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="追回优惠金额"
            label-for="pursue_subsidy_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="pursue_subsidy_amount"
              size="sm"
              v-model="customerservice.pursue_subsidy_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label=""
            label-for="sales_order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_order_id"
              size="sm"
              v-model="customerservice.sales_order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售订单明细ID"
            label-for="sales_order_item_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_order_item_id"
              size="sm"
              v-model="customerservice.sales_order_item_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="customerservice.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="是否有更新"
            label-for="is_update"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="is_update"
              size="sm"
              v-model="customerservice.is_update"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="关联次数"
            label-for="relation_times"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="relation_times"
              size="sm"
              v-model="customerservice.relation_times"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="customerservice.create_time"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import customerserviceStore from './customerserviceStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      customerservice: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('customerservice')) store.registerModule('customerservice', customerserviceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('customerservice')) store.unregisterModule('customerservice')
    })

    const edit = function() {
      store.dispatch('customerservice/edit', {id: this.id}).then(res => {
        this.customerservice = res.data.data
      })
    }

    const view = function() {
      store.dispatch('customerservice/view', {id: this.id}).then(res => {
        this.customerservice = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('customerservice/save', this.customerservice).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
